<template>
  <div>
    <b-row>
      <b-col
          cols="6"
          md="6"
          class="mb-md-0 mb-2"
          v-if="canShowTradingChart"
      >
        <chart
            ref="tradingChart"
            report-name="trading-overview"
            :is-loading.sync="tradingChart.loading"
            :constraint="tradingChart.constraints"
        />
      </b-col>
      <b-col
          cols="6"
          md="6"
          class="mb-md-0 mb-2"
          v-if="canShowPlatformChart"
      >
        <chart
            ref="platformChart"
            report-name="platform-report"
            :is-loading.sync="platformChart.loading"
            :constraint="platformChart.constraints"
        />
      </b-col>
      <b-col
          cols="6"
          md="6"
          class="mb-md-0 mb-2"
          v-if="canShowCountryChart"
      >
        <chart
            ref="countryChart"
            report-name="country-report"
            :is-loading.sync="countryChart.loading"
            :constraint="countryChart.constraints"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Chart from "@/views/reports/components/Chart.vue";

export default {
  components: {
    Chart,
  },
  computed: {
    canShowTradingChart() {
      return this.can('trading-overview', 'read')
    },
    canShowPlatformChart() {
      return this.can('platform-report', 'read')
    },
    canShowCountryChart() {
      return this.can('country-report', 'read')
    }
  },
  data() {
    return {
      tradingChart: {
        loading: false,
        constraints: {
          countDaysChart: 12,
          countMonthsChart: 12,
        }
      },
      platformChart: {
        loading: false,
        constraints: {
          shares: false,
          liveshare: false,
        }
      },
      countryChart: {
        loading: false,
        constraints: {
          growth: false,
        }
      }
    }
  },
  mounted() {
    if(this.canShowTradingChart) this.$refs.tradingChart.getData();
    if(this.canShowPlatformChart) this.$refs.platformChart.getData();
    if(this.canShowCountryChart) this.$refs.countryChart.getData();
  }
}
</script>

<style>

</style>
